<template>
  <div class="index">
    <!-- 顶部 -->
    <div class="people-wrap" v-if="ratabledId">
      <div class="yiping b-card">
        <h4>已评价人（<span>{{ this.revieweds.length }}</span>人）</h4>
        <div class="text">
          <span v-for="item in revieweds" :key="item.ratabledId">{{ item.ratabledName }}</span>
        </div>
      </div>
      <div class="weiping b-card">
        <h4>未评价人（<span>{{ this.unRevieweds.length }}</span>人）</h4>
        <div class="text">
          <span v-for="item in unRevieweds" :key="item.ratabledId">{{ item.ratabledName }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="beiping" v-if="list.ratabledName">
        被评人: {{ list.ratabledName }}
      </div>
      <div class="title">
        <strong>{{ list.paper ? list.paper.title : '' }}</strong>
      </div>
      <div class="subtitle">
        <span>{{ list.paper ? list.paper.subtitle : '' }}</span>
      </div>
      <!-- 题目 -->
      <div class="card">
        <template v-if="list.dimensionsVo && list.dimensionsVo.length">
          <dimension-title-detail
            v-for="(item,index) in list.dimensionsVo"
            :key="item.id"
            :item="item"
            :index="index"
            :parent="list.dimensionsVo"
            :errTag="allErrTag"
            :status="list.status"
            @storage-save="storageSave"
          />
        </template>
        <template v-if="(list.dimensionsVo && list.dimensionsVo.length) && (list.titles && list.titles.length)">
          <el-divider><span class="divider">其他</span></el-divider>
        </template>
        <template v-if="list.titles && list.titles.length">
          <title-detail
            ref="titleDetail"
            v-for="(item,index) in list.titles"
            :item="item"
            :key="index"
            :index="index"
            :errTag="allErrTag"
            @storage-save="storageSave"
            :status="list.status"
          />
        </template>

        <div class="btns">
          <el-button type="primary" @click="goBack">返回</el-button>
          <el-button type="primary" v-if="!list.status" @click="reset">重置</el-button>
          <el-button type="primary" v-if="!list.status" @click="submit('list')">提交</el-button>
          <el-button type="warning" v-if="ratabledId && !list.status && unRevieweds.length !== 0" @click="submit('next')">下一位: {{ unRevieweds[0].ratabledName }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/question';
import DimensionTitleDetail from './modules/DimensionTitleDetail'
import TitleDetail from './modules/TitleDetail'
export default {
  components:{DimensionTitleDetail,TitleDetail},
  data() {
    return {
      // rules: {
      //   checkbox: [{ required: true, message: '多选题必填', trigger: ['change']}],
      //   panduan: [{ required: true, message: '判断题必填', trigger: ['change']}],
      //   radio: [{ required: true, message: '单选题必填', trigger: ['change']}],
      // },
      titles: [], //接口其他数据
      dimensions: [], //接口维度数据
      list: {}, //接口全部数据
      cloneList: {},
      paperId: '',
      ratabledId: '',
      revieweds: [],
      unRevieweds: [],
      // 缓存
      storageArr: [],
      storageObj: {},
      allErrTag:false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop, true);
    this.paperId = this.$route.query.paperId;
    this.ratabledId = this.$route.query.ratabledId;
    this.fetchData();
  },
  methods: {
    // 逻辑跳题
    jumpTitle(row){
      let start = row.sort + 1;
      let end;
      row.optionsList.forEach((item)=>{
        if(row.answer == item.id){
          end = item.jumpTitleSort
        }
      })
      for (let i = start; i < this.list.titles.length; i++) {
        this.list.titles[i].isDisplay = "1";
      }
      if(!isNaN(end)){
        if(end == -1){
          for (let index = start; index < this.list.titles.length; index++) {
            this.list.titles[index].isDisplay = "0";
            this.formatVal(index);
          }
        }
        for (let index = start; index < end; index++) {
          this.list.titles[index].isDisplay = "0";
        }
      }
    },
    storageSave(row) {
      this.jumpTitle(row)
      // 暂时停用缓存功能 且 矩阵体型并未存入该缓存中
      return
      var option = []
      if(row.questionType === 1 || row.questionType === 2){
        row.optionsList.forEach(w=>{
          option.push({id:w.id,isSupplement:w.isSupplement,supplement:w.supplement})
        })
      }
      var answer=[]
      //填空题
      if(row.questionType === 5 ){
        answer =  row.optionsList
        row.answer = answer
      }
      // 保存缓存
      if (this.storageArr.length) {
        var index = '';
        this.storageArr.forEach(item => {
          index = this.storageArr.findIndex(item => {
            return item.key == row.id;
          });
        });
        if (index >= 0) {
          if (row.answer) {
            this.storageArr.splice(index, 1, {
              key: row.id,
              value: row.answer,
              option: option
            });
          } else {
            this.storageArr.splice(index, 1);
          }
        } else if (index == -1) {
          this.storageArr.push({
            key: row.id,
            value: row.answer,
            option: option
          });
        }
      } else {
        this.storageArr.push({
          key: row.id,
          value: row.answer,
          option: option
        });
      }
      this.storageObj = {
        [this.paperId + '-' + this.ratabledId]: this.storageArr,
      };
      localStorage.setItem('storageQueObj', JSON.stringify(this.storageObj));
    },
    scrollToTop() {
      const that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
    },
    // 重置
    reset() {
      this.storageObj = {};
      this.storageArr = [];
      // localStorage.removeItem('storageQueObj');
      this.list = this.cloneList
      this.allErrTag = false
    },
    // 获取已评未评
    getData() {
      api.getRataList({
        paperId: this.paperId
      }).then(response => {
        if (response.success) {
          if(this.$route.query.type=="nostart"){
            this.revieweds = response.result.revieweds.filter(item=>{
              return item.ratabledId != this.list.ratabledId
            });
            this.unRevieweds = response.result.unRevieweds.filter(item=>{
              return item.ratabledId != this.list.ratabledId
            });
          }else{
            this.unRevieweds = response.result.unRevieweds
            this.revieweds = response.result.revieweds
          }
        } else {
          this.$message({
            message: '请求异常',
            type: 'warning',
          });
        }
      });
    },

    async fetchData() {
      api.getList({
        paperId: this.paperId,
        ratabledId: this.ratabledId,
      }).then(res => {
        if (res.success) {
          this.formatAnswerArr(res.result.titles)
          this.formatAnswerArr(res.result.dimensionsVo)
          this.list = res.result;
          this.cloneList = JSON.parse(JSON.stringify(res.result))
          // this.showData();
          this.getData();
        } else {
          this.$message({
            message: res.message,
            type: 'warning',
          });
        }
      });
    },
    handleStorage(list){
      list.forEach(item=>{
        if(!item.orTitle){
          this.handleStorage(item.children)
        }else{
          if (localStorage.getItem('storageQueObj')) {
            let storageObj = JSON.parse(localStorage.getItem('storageQueObj'));
            if (Object.keys(storageObj).toString() === this.paperId + '-' + this.ratabledId) {
              this.storageArr = Object.values(storageObj)[0];
              this.storageArr.forEach((rs)=>{
                  if (rs.key === item.id) {
                      if(item.questionType === 5){
                        if(rs.value.length>0){
                          item.optionsList =  rs.value;
                        }
                      }
                      item.answer = rs.value;
                      if(item.questionType === 1 || item.questionType === 2){
                        item.optionsList.forEach(d=>{
                          rs.option.forEach(f=>{
                            if(d.id===f.id){
                              d.isSupplement = f.isSupplement
                              d.supplement = f.supplement
                            }
                          })
                        })
                      }
                  }
              })
            }
          }
          if (item.questionType === 2) {
            if (!item.answer) {
              item.answer = [];
            }
          }
        }
      })
    },
    // 接口回显数据
    showData() {
      if(this.list){
        this.handleStorage(this.list.dimensionsVo)
        this.handleStorage(this.list.titles)
      }
    },
    // 返回上一页
    goBack() {
      if (this.ratabledId) {
        this.$router.go(-1);
        // this.$router.push({
        //   name: 'qlist',
        // });
      } else {
        this.$router.push({
          name: 'MyQuestion',
        });
      }
    },
    // 递归校验必填
    requiredCheck(tree){
      let arr = [];
      tree.forEach((item)=>{
        if(item.children && item.children.length){
          arr = arr.concat(this.requiredCheck(item.children))
        }else{
          if(item.isDisplay == '1'){
            if (item.questionType === 1 || item.questionType === 4) {
              if (item.answer === null || item.answer === '') {
                arr.push(item);
              }
            }
            if(item.questionType === 2){
              if (!item.answer.length) {
                arr.push(item);
              }
            }
          }
        }
      })
      return arr
    },
    // 递归 单选arr转string
    formatAnswer(data){
      data.forEach((item)=>{
        if(item.orTitle){
          if(item.questionType == 2){
            if(item.answer){
              item.answer = item.answer.join()
            }
          }
          if(item.questionType == 5){
            item.answer = ""
          }
          if(item.questionType == 7){
            item.jzTitle.forEach(li=>{
              li.answer = li.answer.join()
            })
          }
        }else{
          this.formatAnswer(item.children)
        }
      })
    },
    // 递归 单选string转arr
    formatAnswerArr(data){
      data.forEach((item)=>{
        if(item.orTitle){
          if(item.questionType == 2){
            if(item.answer){
              item.answer = item.answer.split(',')
            }else{
              item.answer = []
            }
          }
          if(item.questionType == 7){
            item.jzTitle.forEach(li=>{
              if(li.answer){
                li.answer = li.answer.split(',')
              }else{
                li.answer = []
              }
            })
          }
        }else{
          this.formatAnswerArr(item.children)
        }
      })
    },
    // 提交
    submit(type) {
      this.allErrTag = true
      let checkedList = []
      if(this.list.titles.length){
        checkedList = checkedList.concat(this.requiredCheck(this.list.titles))
      }
      if(this.list.dimensionsVo.length){
        checkedList = checkedList.concat(this.requiredCheck(this.list.dimensionsVo))
      }
      if(checkedList.length){
        this.$message({
          type: 'warning',
          message: '请将必填项完成',
        });
        return
      }else{
        // console.log(this.list.titles,this.list.dimensionsVo,'001')
        // return
        if(this.list.titles.length){
          this.formatAnswer(this.list.titles)
        }
        if(this.list.dimensionsVo.length){
          this.formatAnswer(this.list.dimensionsVo)
        }
        api.save(this.list).then(response => {
          if (response.success) {
            localStorage.removeItem('storageQueObj');
            // 下一位
            if (type == 'next') {
              this.ratabledId = this.unRevieweds[0].ratabledId;
              this.fetchData();
              this.allErrTag = false
              this.$message({
                type: 'warning',
                message: `评价成功,跳转到${this.unRevieweds[0].ratabledName}`,
              });
            } else {
              this.list.status = true;
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.fetchData();
            }
          } else {
            this.$message({
              type: 'success',
              message: response.message,
            });
            this.fetchData();
          }
        });
      }
    },
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';

.divider {
  font-size: 16px;
}
// .index {
//   width: 91%;
//   min-height: calc(100vh - 264px);
// }
// .el-button {
//   border: none;
// }

.people-wrap{
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
  .b-card{
    flex: 1;
    padding: 20px;
    background: #fff;
    margin: 0 20px;
  }
  h4{
    font-size: 16px;
    font-weight: normal;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  .text{
    color: #575757;
    line-height: 26px;
    margin-left: 34px;
    span{
      font-size: 14px;
    }
    span:after {
      content: '、';
    }
    span:last-child {
      &:after {
        content: '';
      }
    }
  }
  .yiping{
    h4{
      color: #53be95;
      background: url(~@/assets/img/06_MY_question/yiping.png) no-repeat left top;
      background-size: auto;
    }
  }
  .weiping{
    h4{
      color: #ff8e26;
      background: url(~@/assets/img/06_MY_question/weiping.png) no-repeat left top;
      background-size: auto;
    }
  }
}

.subtitle{
  text-align: center;
  margin-bottom: 20px;
}

.content {
  background-color: #ffffff;
  padding: 20px 33px 40px 30px;
  box-sizing: border-box;
  margin-top: 20px;
  .beiping {
    display: inline-block;
    color: #307ef4;
    margin-left: -30px;
    padding: 10px 20px 10px 30px;
    border-radius: 0px 20px 20px 0px;
    background: #e7f4ff url('../../assets/img/06_MY_question/user.png') no-repeat 10px center;
    background-size: 16px;
  }
  .title {
    text-align: center;
    font-size: 28px;
    padding-top: 19px 0 29px;
    strong{
      display: inline-block;
      padding: 0 120px;
      position: relative;
      margin-bottom: 8px;
      &::before{
        content: '';
        display: block;
        width: 48px;
        height: 27px;
        background: url(~@/assets/img/base/title_left.png) no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
      &::after{
        content: '';
        display: block;
        width: 48px;
        height: 27px;
        background: url(~@/assets/img/base/title_right.png) no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
  .card {
    border: 2px solid #f6f6fc;
    box-shadow: 8px 14px 18px 0px rgba(243, 245, 255, 0.72);
    border-radius: 10px;
    padding: 32px;
    // /deep/.el-form {
    //   &-item {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     &__label {
    //       text-align: left;
    //       font-size: 18px;
    //     }
    //     &__content {
    //       width: 100%;
    //       .el-radio-group {
    //         .el-radio {
    //           // padding-right: 132px;
    //           &:first-child {
    //             padding-left: 20px;
    //           }
    //           &:last-child {
    //             padding-right: 0;
    //           }
    //         }
    //         .el-radio__input.is-disabled.is-checked .el-radio__inner {
    //           border-color: #3f84ee;
    //           &::after {
    //             background-color: #3f84ee;
    //           }
    //         }
    //         .el-radio__input.is-disabled + span.el-radio__label {
    //           color: #747b86;
    //           font-size: 18px;
    //         }
    //       }
    //       .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    //         // background-color: #747b86;
    //         &::after {
    //           border-color: #3f84ee;
    //         }
    //       }
    //       .el-checkbox__input.is-disabled + span.el-checkbox__label {
    //         color: #747b86;
    //       }
    //       .el-table {
    //         &__row {
    //           .el-radio__label {
    //             display: none;
    //             font-size: 18px;
    //           }
    //         }
    //         &::before {
    //           height: 0;
    //         }
    //       }
    //       .el-table td.el-table__cell,
    //       .el-table th.el-table__cell.is-leaf {
    //         border-bottom: none;
    //       }
    //       .el-textarea {
    //         font-size: 18px;
    //         textarea {
    //           margin: 0 auto;
    //           min-height: 86px;
    //         }
    //       }
    //       .el-textarea__inner {
    //         padding: 10px 20px 30px 20px;
    //         box-sizing: border-box;
    //       }
    //       .el-form-item__error{
    //         padding-left: 20px;
    //       }
    //     }
    //     .btn-prev,
    //     .btn-next {
    //       width: 114px;
    //       border-radius: 6px;
    //     }
    //   }
    //   .grade {
    //     .el-radio-group,
    //     .el-checkbox-group {
    //       display: flex;
    //       flex-direction: column;
    //       padding-left: 20px;
    //       box-sizing: border-box;
    //       .el-radio,
    //       .el-checkbox {
    //         line-height: 52px;
    //         width: 100%;
    //         text-overflow: ellipsis;
    //         white-space: normal;
    //         &:first-child {
    //           padding-left: 0;
    //         }
    //       }
    //       .el-radio__label,
    //       .el-checkbox__label {
    //         font-size: 18px !important;
    //         word-break: break-all;
    //       }
    //     }
    //   }
    // }
    .btns {
      text-align: center;
      margin: 20px 0;
    }
  }
}

.guidder{
  font-size: 14px;
  color: #f0943f;
  margin-left: 12px
}
.score{
  color: #d8d8d8;
  margin-left: 12px
}
</style>
