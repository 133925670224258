<template>
  <div :class="`level${item.level}-card`">
    <div v-if="item.orTitle">
      <title-detail :item="item" :index="index" @storage-save="storageSave" :errTag="errTag" :status="status"></title-detail>
    </div>
    <template v-else>
      <div class="level-title">{{index+1}}、
        <strong class="name">{{ item.name }}</strong>
      </div>
      <dimension-title-detail
        v-for="(child, j) in item.children"
        :key="child.id"
        :item="child"
        :index="j"
        :parnet="item.children"
        :errTag="errTag"
        :status="status"
        @storage-save="storageSave"
      >
      </dimension-title-detail>
    </template>
  </div>
</template>

<script>
import TitleDetail from './TitleDetail.vue';
export default {
  components:{TitleDetail},
  name:'DimensionTitleDetail',
  props:{
    // 循环的item
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    parnet:{
      type: Array
    },
    errTag:{
      typeof: Boolean,
      default: false
    },
    status:{
      typeof:Boolean,
      default:false
    }
  },
  methods:{
    storageSave(row){
      this.$emit('storage-save',row)
    }
  }
}
</script>

<style lang="scss" scoped>
.level1-card{
  margin-top: 20px;
  &>.level-title{
    background: #5c90cb;
    padding: 10px 15px;
    font-size: 20px;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}
.level2-card{
  &>.level-title{
    background: #f2faff;
    border: 1px dashed #5c90cb;
    border-radius: 10px;
    padding: 10px 15px;
    margin: 10px 0;
    font-size: 18px;
    color: #1f66b7;
  }
}
.level3-card{
  &>.level-title{
    padding-top: 5px;
    border-bottom: 1px solid #dcdcdc;
    font-size: 16px;
    margin: 0 15px 10px;
    .name{
      border-bottom: 2px solid #1f66b7;
      padding-bottom: 5px;
      display: inline-block;
    }
  }
}
</style>
