import { render, staticRenderFns } from "./DimensionTitleDetail.vue?vue&type=template&id=338ce8d5&scoped=true"
import script from "./DimensionTitleDetail.vue?vue&type=script&lang=js"
export * from "./DimensionTitleDetail.vue?vue&type=script&lang=js"
import style0 from "./DimensionTitleDetail.vue?vue&type=style&index=0&id=338ce8d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338ce8d5",
  null
  
)

export default component.exports