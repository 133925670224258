<template>
  <div>
    <div v-if="item.questionType === 1 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <span class="sort">{{"Q"+ (index+1)}}</span>
        <span><small class="required">*</small>{{item.name}}</span>
        <span class="score" v-if="maxScore(item.optionsList) != 0">(最高分值{{maxScore(item.optionsList)}}分)</span>
      </div>
      <div class="q-answer">
        <el-radio-group v-model="item.answer" @change="storageSave(item)">
          <el-radio v-for="li in item.optionsList" :key="li.id" :label="li.id" class="radio" :disabled="status">
            {{li.name}}
            <span class="score" v-if="parseInt(li.score) != 0">(分值:{{li.score}}分)</span>
            <el-input v-if="parseInt(li.isSupplement) && item.answer &&  item.answer.indexOf(li.id) != -1" v-model="li.supplement" @blur="storageSave(item)" class="opt-input" :disabled="status">
            </el-input>
            <span v-if="parseInt(li.jumpTitleId)" class="guidder"> 
              <template v-if="li.jumpTitleId == -1">
                {{li.jumpTitleName}}
              </template>  
              <template v-else>
                跳转到 <strong>第{{ li.jumpTitleSort + 1 }}题</strong>
              </template>                
            </span>
          </el-radio>
        </el-radio-group>
      </div>
      <div class="err" v-if="!item.answer && errTag">该项为必填项</div>
    </div>
    <div v-if="item.questionType === 2 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <span class="sort">{{"Q"+ (index+1)}}</span>
        <span><small class="required">*</small>{{item.name}}</span>
      </div>
      <div class="q-answer">
        <el-checkbox-group v-model="item.answer" @change="storageSave(item) " :disabled="status">
          <el-checkbox
            v-for="li in item.optionsList"
            :key="li.id"
              class="checkbox"
            name="answer"
            :label="li.id">{{li.name}}
            <el-input v-if="parseInt(li.isSupplement) && item.answer && item.answer.indexOf(li.id) != -1"  v-model="li.supplement" @blur="storageSave(item)" class="opt-input" :disabled="status">
            </el-input>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="err" v-if="!item.answer.length && errTag">该项为必填项</div>
    </div>
    <div v-if="item.questionType === 3 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <span class="sort">{{"Q"+ (index+1)}}</span>
        <span>{{item.name}}</span>
      </div>
      <div class="q-answer">
        <el-input type="textarea" :autosize="{ minRows: item.frameSize, maxRows: item.frameSize}"  v-model="item.answer" @change="storageSave(item)" :disabled="status"></el-input>
      </div>
    </div>
    <div v-if="item.questionType === 4 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <span class="sort">{{"Q"+ (index+1)}}</span>
        <span><small class="required">*</small>{{item.name}}</span>
        <span class="score" v-if="maxScore(item.optionsList) != 0">(最高分值{{maxScore(item.optionsList)}}分)</span>
      </div>
      <div class="q-answer">
        <el-radio-group v-model="item.answer" @change="storageSave(item)" :disabled="status">
          <el-radio v-for="li in item.optionsList" :key="li.id" :label="li.id" class="radio">
            {{li.name}}
            <span class="score" v-if="parseInt(li.score) != 0">(分值:{{li.score}}分)</span>
            <span v-if="parseInt(li.jumpTitleId)" class="guidder"> 
              <template v-if="li.jumpTitleId == -1">
                {{li.jumpTitleName}}
              </template>  
              <template v-else>
                跳转到 <strong>第{{ li.jumpTitleSort + 1 }}题</strong>
              </template>                
            </span>
          </el-radio>
        </el-radio-group>
      </div>
      <div class="err" v-if="!item.answer && errTag">该项为必填项</div>
    </div>
    <div v-if="item.questionType === 5 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <span class="sort">{{"Q"+ (index+1)}}</span>
        <div class="name">
          <span v-for="(li,i) in item.name.split(/（\s*）/g) " :key="i">
            {{ li }}
            <el-input v-if="i < item.name.split(/（\s*）/g).length - 1" style="width:150px;" size="small" v-model="item.optionsList[i].supplement"  @change="storageSave(item)" :disabled="status"/>
          </span>
        </div>
      </div>
    </div>

    <!--  矩阵题6  -->
    <div v-if="item.questionType === 6 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <div class="text">
          <span class="sort">{{"Q"+ (index+1)}}</span>
          <span>{{item.name}}</span>
        </div>
      </div>
      <div class="q-answer card-main">
        <div class="opt-name">
          <div class="name" v-for="li in item.optionsList" :key="li.id">{{li.name}}</div>
        </div>
        <div class="row" v-for="(jz,j) in item.jzTitle" :key="j">
          <div class="row-name">{{jz.name}}</div>
          <div class="row-opt">
            <!-- @change="storageSave(item)" -->
            <el-radio-group v-model="jz.answer" :disabled="status">
              <el-radio v-for="li in item.optionsList" :label="li.id" :key="li.id"  class="radio">
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.questionType === 7 && item.isDisplay == '1' " class="title-card">
      <div class="q-title">
        <div class="text">
          <span class="sort">{{"Q"+ (index+1)}}</span>
          <span>{{item.name}}</span>
        </div>
      </div>
      <div class="q-answer card-main">
        <div class="opt-name">
          <div class="name" v-for="li in item.optionsList" :key="li.id">{{li.name}}</div>
        </div>
        <div class="row" v-for="(jz,j) in item.jzTitle" :key="j">
          <div class="row-name">{{jz.name}}</div>
          <div class="row-opt">
            <!-- @change="storageSave(item)" -->
            <el-checkbox-group v-model="jz.answer" :disabled="status">
              <el-checkbox
                v-for="li in item.optionsList"
                :key="li.id"
                :label="li.id"
                class="checkbox"
                name="answer">
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'TitleDetail',
  props:{
    item:{
      type: Object,
      default:{ }
    },
    index:{
      type: Number,
      default: 0
    },
    errTag:{
      typeof: Boolean,
      default: false
    },
    status:{
      typeof:Boolean,
      default:false
    }
  },
  data() {
    return{

    }
  },
  mounted() {
    this.ratabledId = this.$route.query.ratabledId;
  },
  methods:{
    storageSave(row) {
      this.$emit('storage-save',row)
    }
  },
  filters:{

  },
  computed: {
    maxScore() {
      return function (options) {
        if (options && options.length > 0) {
          let max = Math.max.apply(
              Math,
              options.map((option) => {
                return option.score
              })
          )
          return max
        }
        return 0
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.title-card{
  padding: 10px 0 25px;
  position: relative;
  .q-title{
    display: flex;
    font-size: 16px;
    line-height: 2.4;
    .sort{
      margin-right: 20px;
    }
    .required{
      font-size: 16px;
      color: #f56c6c;
      margin-right: 3px;
    }
  }
  .q-answer{
    margin-top: 5px;
  }
  .score{
    margin-left: 15px;
    color: #999;
  }
  .err{
    font-size: 14px;
    color: #f56c6c;
    position: absolute;
    left: 40px;
    bottom:0
  }
}

.el-textarea{
  margin-left: 40px;
  display: block;
  width: auto;
}

.el-radio-group{
  margin-left: 40px;
  /deep/ .el-radio{
    display: flex;
    margin-top: 15px;
    .el-radio__label{
      flex: 1;
      white-space: normal;
    }
  }
}
.el-checkbox-group{
  margin-left: 40px;
  /deep/ .el-checkbox{
    display: flex;
    margin-top: 15px;
    .el-checkbox__input{
      margin-top: 3px;
    }
    .el-checkbox__label{
      flex: 1;
      white-space: normal;
    }
  }
}
.opt-input{
  display: block;
  width: 200px;
  margin-top: 5px;
}
.card-main{
  padding: 0 42px 10px;
  overflow: auto;
  .opt-name{
    width: 70%;
    margin-left: 30%;
    text-align: right;
    white-space: nowrap;
    .name{
      display: inline-block;
      width: calc(100%/4 - 20px);
      margin: 0 10px;
      text-align: center;
      white-space: normal;
      box-sizing: border-box;
    }
  }
  .row{
    display: flex;
    // align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    .row-name{
      width: 30%;
    }
    .row-opt{
      width: 70%;
      white-space: nowrap;
      /deep/ .el-radio-group,
      /deep/ .el-checkbox-group{
        width: 100%;
        margin-left: 0;
        text-align: right;
        .el-radio{
          display: inline-block;
          width: calc(100%/4 - 20px);
          margin-right: 0;
          margin: 0 10px;
          text-align: center;
          .el-radio__label{
            display: none;
          }
        }
        .el-checkbox{
          display: inline-block;
          width: calc(100%/4 - 20px);
          margin-right: 0;
          margin: 0 10px;
          text-align: center;
          .el-checkbox__label{
            display: none;
          }
        }
      }
    }
  }
  .row+.row{
    // border-top: 1px solid #f2f2f2;
    .row-name{
      position: relative;
      &::before{
        display: block;
        width: 100%;
        height: 1px;
        background: #f2f2f2;
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
      }
    }
    .el-radio,
    .el-checkbox{
      position: relative;
      &::before{
        display: block;
        height: 1px;
        background: #f2f2f2;
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
      }
    }
    .el-radio{
      &::before{
        top: -15px;
      }
      
    }
  }
}
.guidder{
  font-size: 14px;
  color: #f0943f;
  margin-left: 12px
}
.score{
  color: #d8d8d8; 
  margin-left: 12px
} 
.el-input+.guidder{
  display: inline-block;
  margin-top: 5px;
  margin-left: 0;
}
</style>
